import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: () => import("../layouts/AuthLayouts/AuthLayout"),
    children: [
      {
        path: "sign-in",
        name: "auth.sign-in",
        component: () => import("../views/fisai/auth/SignIn"),
      },
      {
        path: "sign-up",
        name: "auth.sign-up",
        component: () => import("../views/fisai/auth/SignUp"),
      },
      {
        path: "password-reset",
        name: "auth.password-reset",
        component: () => import("../views/fisai/auth/RecoverPassword"),
      },
      {
        path: "confirm-mail",
        name: "auth.confirm-mail",
        component: () => import("../views/fisai/auth/ConfirmMail"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "home-1",
        name: "dashboard.home-1",
        meta: { name: "Home 1", requiresAuth: true },
        component: () => import("../views/fisai/dashboards/AdminView"),
      },
      {
        path: "",
        name: "dashboard.home-4",
        meta: { name: "Home 4", requiresAuth: true },
        component: () => import("../views/fisai/dashboards/DoctorView"),
      },
      {
        path: "home-3",
        name: "dashboard.home-3",
        meta: { name: "Home 3", requiresAuth: true },
        component: () => import("../views/fisai/dashboards/PatientView"),
      },
    ],
  },
  {
    path: "/app",
    name: "app",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "calendar",
        name: "app.calendar",
        meta: { name: "Calendar", requiresAuth: true },
        component: () => import("../views/Apps/Calendar/Calendar"),
      },
    ],
  },
  {
    path: "/doctors",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "doctor.list",
        meta: { name: "Doctor List", requiresAuth: true },
        component: () => import("../views/fisai/doctors/IndexView"),
      },
      {
        path: "detail/:id",
        name: "doctor.detail",
        meta: { name: "Doctor Detail", requiresAuth: true },
        component: () => import("../views/fisai/doctors/DetailView"),
      },
    ],
  },
  {
    path: "/patients",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "patient.list",
        meta: { name: "Patient List", requiresAuth: true },
        component: () => import("../views/fisai/patients/IndexView"),
      },
      {
        path: "detail",
        name: "patient.detail",
        meta: { name: "Patient Detail", requiresAuth: true },
        component: () => import("../views/fisai/patients/DetailView"),
      },
      {
        path: "edit",
        name: "patient.edit",
        meta: { name: "Patient Edit", requiresAuth: true },
        component: () => import("../views/fisai/patients/EditView"),
      },
      {
        path: "medical-history/:id",
        name: "patient.medical.history",
        meta: { name: "Patient Medical History", requiresAuth: true },
        component: () => import("../views/fisai/patients/HistoryView"),
      },
      {
        path: "physiotherapeutic-history/:id",
        name: "patient.physiotherapeutic.history",
        meta: { name: "Patient Physiotherapeutic History", requiresAuth: true },
        component: () => import("../views/fisai/patients/PhysiotherapeuticView"),
      },
      {
        path: "evolution-notes/:id",
        name: "patient.evolution.notes",
        meta: { name: "Patient Evolution Notes", requiresAuth: true },
        component: () => import("../views/fisai/patients/EvolutionNotes"),
      },
    ],
  },
  {
    path: "/services",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "service.list",
        meta: { name: "Service List", requiresAuth: true },
        component: () => import("../views/fisai/services/IndexView"),
      },
    ],
  },
  {
    path: "/tips",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "tip.list",
        meta: { name: "Tip List", requiresAuth: true },
        component: () => import("../views/fisai/tips/IndexView"),
      },
    ],
  },
  {
    path: "/tips2",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "tip.list2",
        meta: { name: "Tip List 2", requiresAuth: true },
        component: () => import("../views/fisai/tips/TipsView"),
      },
    ],
  },
  {
    path: "/medical-history",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "history.list",
        meta: { name: "History List", requiresAuth: true },
        component: () => import("../views/fisai/medical-history/IndexView"),
      },
    ],
  },
  {
    path: "/glycemia",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "glycemia.list",
        meta: { name: "glycemia List", requiresAuth: true },
        component: () => import("../views/fisai/glycemia/IndexView"),
      },
    ],
  },
  {
    path: "/treatments",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "treatment.list",
        meta: { name: "Treatment List", requiresAuth: true },
        component: () => import("../views/fisai/treatments/IndexView"),
      },
    ],
  },
  {
    path: "/appointments",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "appointment.list",
        meta: { name: "Appointment List", requiresAuth: true },
        component: () => import("../views/fisai/appointments/IndexView"),
      },
    ],
  },
  {
    path: "/configuration",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "configuration",
        meta: { name: "Configuration List", requiresAuth: true },
        component: () => import("../views/fisai/configuration/IndexView"),
      },
    ],
  },
  {
    path: "/roles",
    component: () => import("../layouts/Layout"),
    children: [
      {
        path: "",
        name: "role.list",
        meta: { name: "Role List", requiresAuth: true },
        component: () => import("../views/fisai/roles/IndexView"),
      },
      {
        path: "detail/:id",
        name: "role.detail",
        meta: { name: "Role Detail", requiresAuth: true },
        component: () => import("../views/fisai/roles/DetailView"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuth = localStorage.getItem("isLoggedIn");
  let requiresAuth = to.meta.requiresAuth || false;

  if (requiresAuth && !isAuth) {
    next("/auth/sign-in");
  }
  next();
});

export default router;
