import Vue from "vue";
import "mutationobserver-shim";
import "@babel/polyfill";
import "./Utils/fliter";
import App from "./App.vue";
import router from "./router/starter";
import Raphael from "raphael/raphael";
import "./plugins";
import { createPinia, PiniaVuePlugin } from "pinia";

global.Raphael = Raphael;
Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.directive("user-can", {
  bind(el, binding, vnode) {
    const user = JSON.parse(localStorage.getItem("user"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const { value } = binding;

    if (user.role === "Super Admin" || permissions.includes(value)) {
      return;
    }

    el.parentNode && el.parentNode.removeChild(el);
  },
});

let vm = new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
